import { useState } from "react";
import { Grid } from "@mui/material";
import { BannerSearchListResponseDataItem } from "../../../../api/content-client/service";
import { BannerWidget } from "../../../widgets/BannerWidget/BannerWidget";
import { useRouter } from "next/router";

type Props = {
  banners: BannerSearchListResponseDataItem[];
  currentPage: number;
  position: number;
  showAll?: boolean;
  compactWidth?: boolean;
};

export function bannerSizeToGridItemSmWidth(size: "one" | "two" | "four"): number {
  if (size === "one") {
    return 6;
  }
  if (size === "two") {
    return 12;
  }
  return 12;
}

export function bannerSizeToGridItemMdWidth(size: "one" | "two" | "four", compactWidth?: boolean): number {
  if (size === "one") {
    return compactWidth ? 6 : 4;
  }
  if (size === "two") {
    return compactWidth ? 12 : 8;
  }
  return 12;
}

export function bannerSizeToGridItemLgWidth(size: "one" | "two" | "four", compactWidth?: boolean): number {
  if (size === "one") {
    return compactWidth ? 4 : 3;
  }
  if (size === "two") {
    return compactWidth ? 8 : 6;
  }
  return 12;
}

function matchUrlWithPattern(url: string, pattern: string): boolean {
  const parseUrl = (url: string) => {
    const [path, queryString] = url.split("?");
    const query: Record<string, string> = {};
    if (queryString) {
      queryString.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        if (key) {
          query[key] = value || "";
        }
      });
    }
    return { path, query };
  };

  const comparePaths = (urlPath: string, patternPath: string): boolean => {
    const urlSegments = urlPath.split("/");
    const patternSegments = patternPath.split("/");

    if (urlSegments.length !== patternSegments.length) {
      return false;
    }

    for (let i = 0; i < patternSegments.length; i++) {
      if (patternSegments[i] !== "*" && patternSegments[i] !== urlSegments[i]) {
        return false;
      }
    }
    return true;
  };

  const parsedUrl = parseUrl(url);
  const parsedPattern = parseUrl(pattern);

  // Проверяем путь с учетом маски
  if (!comparePaths(parsedUrl.path, parsedPattern.path)) {
    return false;
  }

  // Проверяем параметры
  for (const key in parsedPattern.query) {
    const patternValue = parsedPattern.query[key];
    const urlValue = parsedUrl.query[key];

    // Если в маске есть определенное значение, проверяем на соответствие
    if (patternValue !== "*" && patternValue !== urlValue) {
      return false;
    }
  }

  return true;
}

function BannerItem({ banner, compactWidth }: { banner: BannerSearchListResponseDataItem; compactWidth?: boolean }) {
  const [isRaised, setRaised] = useState(false);
  return (
    <Grid
      onMouseEnter={() => setRaised(true)}
      onMouseLeave={() => setRaised(false)}
      onTouchStart={() => setRaised(true)}
      item
      xs={12}
      sm={bannerSizeToGridItemSmWidth(banner.attributes?.size || "one")}
      md={bannerSizeToGridItemMdWidth(banner.attributes?.size || "one", compactWidth)}
      lg={bannerSizeToGridItemLgWidth(banner.attributes?.size || "one", compactWidth)}
      sx={{
        transition: "all .1s ease-in",
        transform: isRaised ? "translateY(-1%)" : undefined,
      }}
    >
      <BannerWidget
        linkUrl={banner.attributes?.linkUrl ?? ""}
        {...banner.attributes}
        containerSx={{
          minHeight: 296,
        }}
        backgroundDimming={false}
      />
    </Grid>
  );
}

export const BannersSearch = (props: Props) => {
  const { banners, currentPage, position, showAll, compactWidth } = props;

  const { asPath } = useRouter();

  if (!banners?.length) {
    return null;
  }

  if (showAll) {
    return banners.map((banner, i) => <BannerItem key={i} banner={banner} />);
  }

  const foundBanner = banners.find((banner) => {
    const { page, position: bannerPosition, urlPattern } = banner.attributes || {};

    if (page !== currentPage) {
      return false;
    }
    if (bannerPosition !== position) {
      return false;
    }

    if (urlPattern && !matchUrlWithPattern(asPath, urlPattern)) {
      return false;
    }

    return true;
  });

  return foundBanner ? <BannerItem banner={foundBanner} compactWidth={compactWidth} /> : null;
};
